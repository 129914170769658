'use strict'

###*
 # @ngdoc service
 # @name users.factory:User

 # @description

###
angular
  .module 'users'
  .factory 'UserManager', [
    '$rootScope'
    'MundoDefaultManager'
    (
      $rootScope
      MundoDefaultManager
    ) ->
      UserBase =  new MundoDefaultManager()

      UserBase.setActions(UserBase.getActions().filter (v) -> v != 'delete')
      UserBase.setUrl('users')
      UserBase.setNewObject(['username', 'email', 'enabled'])
      UserBase.setUpdateObject(['username', 'email', 'enabled', 'plainPassword'])
      UserBase.setSearchFields(['username', 'email', 'enabled'])
      UserBase.setObject('userContext', ['user', 'roles', 'userContextGroups', 'tenant'])
      UserBase.defaultQuery.sort = 'username'

      UserBase.new = (data) ->
        @transport.all(@getUrl()).post(_.pick(data, @getNewObject())).then (userObject) =>
          userContextData = {}
          userContextData.user = userObject.id
          userContextData.tenant = $rootScope.user.activeUserContext.tenant.id
          @transport.all('user_contexts').post(_.pick(userContextData, @getObject('userContext')))

      UserBase.getNewForm = ->
        [
          key: 'username'
          name: 'username'
          type: 'input'
          templateOptions:
            label: 'Username'
            placeholder: 'johndoe'
            required: true
            description: 'Descriptive text'
        ,
          key: 'email'
          name: 'email'
          type: 'input'
          templateOptions:
            type: 'email'
            label: 'Email address'
            placeholder: 'Enter email'
            required: true
        ,
          key: 'enabled'
          name: 'enabled'
          type: 'checkbox'
          templateOptions:
            label: 'enabled'
        ]

      UserBase.getEditForm = (data)->
        [
          key: 'username'
          name: 'username'
          type: 'input'
          defaultValue: data.username,
          templateOptions:
            label: 'Username'
            placeholder: 'johndoe'
            required: true
            description: 'Descriptive text'
        ,
          key: 'email'
          name: 'email'
          type: 'input'
          defaultValue: data.email,
          templateOptions:
            type: 'email'
            label: 'Email address'
            placeholder: 'Enter email'
            required: true
        ,
          key: 'plainPassword'
          name: 'plainPassword'
          type: 'input'
          defaultValue: data.plainPassword,
          templateOptions:
            type: 'password'
            label: 'Password'
        ,
          key: 'enabled'
          name: 'enabled'
          type: 'checkbox'
          defaultValue: data.enabled,
          templateOptions:
            label: 'enabled'
        ]

      UserBase.getListSchema = ->
        [
          key: 'username'
          title: 'datatable.label.name'
          sort: 'username'
        ,
          key: 'email'
          title: 'datatable.label.email'
          sort: 'email'
        ,
          key: 'enabled'
          title: 'datatable.label.enabled'
          sort: 'enabled'
          type: 'boolean'
        ]

      #permission needed to edit/delete an entity
      UserBase.editPermission = 'manage all MundoMosaUserBundle:User entities'

      UserBase
  ]
